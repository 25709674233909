body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

/* WSFileUploadForm component */
div.fileupload {
  margin: 2px 10px;
  border: 0.5px solid grey;
  display: inline-block;
}

/*WorkspaceList component*/
span.service-type {
  font-style: italic;
  opacity: 0.5;
  padding-right: 10px;
}

tr.just_created {
  background-color: rgb(252, 252, 191);
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    background-color: rgb(255, 255, 239);
  }
  50% {
    background-color: rgb(252, 252, 142);
  }
  100% {
    background-color: rgb(255, 255, 239);
  }
}

/*TSMetricsGrid component*/
.ts-metric-row {
  height: 34px;
  overflow: hidden;
}
.ts-metric-row span {
  padding: 0px 2px;
  display: inline-block;
}
.ts-metric-row .metric-name {
  width: 200px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.ts-metric-row .hostalias {
  color: #4d4d4d;
  font-style: italic;
}